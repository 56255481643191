<template>
    <v-dialog v-model="visible" :fullscreen="$vuetify.breakpoint.smAndDown" persistent max-width="500" scrollable>
        <v-card :tile="$vuetify.breakpoint.smAndDown" style="max-height:700px"> 
            <v-card-title>
                <h2>Atributos</h2>
                <p class="body-1 mt-3">
                    Exiba atributos no seu Perfil da empresa para que os clientes saibam mais sobre ela. 
                    Eles podem aparecer publicamente na Pesquisa, no Maps e em outros Serviços do Google.
                </p>
            </v-card-title>
            <v-card-text>
                <v-flex xs12 v-for="(grp,i) in atributosList" :key="i">
                    <b>{{grp.descricao}}</b>
                    <v-layout wrap>
                        <v-chip v-for="(opc, iopc) in grp.opc" :key="iopc" :color="isSelect(opc)?'green':'blue'" 
                        outlined class="ma-1" @click="onSelect(opc)">
                            <v-icon :color="isSelect(opc)?'green':'blue'"  class="mr-1">mdi-{{isSelect(opc)?'check':'plus'}}</v-icon>
                            {{opc.descricao}}
                        </v-chip>
                    </v-layout>
                    <v-divider class="my-3"/>
                </v-flex>
            </v-card-text>
            <v-card-actions>
                <v-layout  wrap>
                    <v-flex xs12 md6/>
                    <v-flex xs6 md3 class="pr-1">
                        <v-btn text color="#424242"  @click="$emit('onClose')">
                            CANCELAR
                        </v-btn>
                    </v-flex>
                    <v-flex xs6 md3 class="pl-1">
                        <v-btn text color="blue" @click="onSalvar">
                            APLICAR
                        </v-btn>
                    </v-flex>
                    <v-flex xs12>
                        <v-divider class="my-2"/>
                        <p class="body-2">As edições serão revisadas. Pode levar até três dias para que elas fiquem visíveis na Pesquisa, no Maps e em outros Serviços do Google.</p>
                    </v-flex>
                </v-layout>
               
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props:{
        visible:{
            type: Boolean,
            default: false
        },
        atributosInit:{
            type: Array,
            default: ()=>[]
        },
        atributosList:{
            type: Array,
            default: ()=>[]
        }
    },
    data(){
        return{
            atributos:[]
        }
    },
    methods:{
        isSelect(opc){
            return this.atributos.filter(item=>{return item.atributoID==opc.atributoID}).length==0?false:true
        },
        onSelect(opc){
            if(this.isSelect(opc)==false){
                this.atributos.push(opc);
            }else{
                let indexAttr = this.atributos.findIndex(i => i.atributoID === opc.atributoID);
                this.atributos.splice(indexAttr,1);
            }
        },
        onSalvar(){
            this.$emit("onSalvar",this.atributos);
            this.$emit("onClose");
        }
    },
    watch:{
        visible(){
            if(this.atributosInit.length>0){
                console.log(this.atributosList,this.atributosInit);
                this.atributos = this.atributosInit;
            }
        }
    },
    computed:{
        atributosFilter(){
            return this.atributosList.filter(item=>{
                return item.opc.length>0;
            });
        }
    }
}
</script>
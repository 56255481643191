import axios from "axios";

// Pode ser algum servidor executando localmente: 
// const url = "http://maps.beetechapi.be/";
// const url = "http://localhost:3001/"; 
const url = process.env.NODE_ENV === 'production' ? "https://google.beetechapi.be/" : "http://localhost:3040/";
const apiG = axios.create({
  baseURL: `${url}api/rest/`,
  headers:{
    'Cache-Control':'no-cache'
  },
  auth: {
    username: 'beetech',
    password: '1q2w3e4r'
  }
});


export default apiG;
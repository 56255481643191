<template>
    <v-card color="transparent" class="mt-10 elevation-0" :disabled="disabled">
        <v-layout justify-center align-center>
            <v-card width="60" height="60" style="border-radius:100px;position:absolute;z-index:2;border:5px solid;display:flex;justify-content:center;align-items:center"
            :style="`border-color:${business.verificado==true?'#4CAF50':'#F44336'}`">
                <div  style="width:30px;heigth:30px">
                    <v-img :src="require(`@/assets/${business.verificado==true?'check':'close'}.png`)" 
                    style="width:30px;heigth:30px" contain/>
                </div>
            </v-card>
            <div style="position: absolute;z-index: 2;right: 0px;top: -24px;" :class="$vuetify.breakpoint.smAndDown?'caption':''">
                <b>Status:</b>Conta {{business.verificado?'verificada':'não verificada'}}.
            </div>
            <v-btn v-if="business.verificado==false" color="blue" class="white--text"  :small="$vuetify.breakpoint.smAndDown"
            style="position: absolute;z-index: 2;right: 0px;top: 24px;" :href="linkVerify" target="_blank">
                VERIFICAR CONTA<v-icon class="ml-2" color="white">mdi-shield-check</v-icon>
            </v-btn>
            <v-card height="15" width="100%" :color="business.verificado==true?'#4CAF50':'#F44336'"/>
        </v-layout>
        <v-layout justify-center align-center wrap style="margin-top:50px">
            <v-flex xs12 md6 class="pr-md-1">
                <b>Passo 3 - Categorias:</b>
                <CatAttrList title="CATEGORIAS" @onEditar="showCat=true">
                    <div class="pa-1" style="overflow-y:scroll;height:150px">
                        <ul>
                            <li v-for="(item,i) in (!!business.categorias?business.categorias:[])" :key="i">{{item.categoria}}</li>
                        </ul>
                    </div>
                </CatAttrList>
                <Categorias :visible="showCat" @onClose="showCat=false" :categoriasList="categoriaList" 
                :categoriasInit="!!business.categorias?business.categorias:[]" @onSalvar="(e)=>{business.categorias=e;$emit('onSalvarB')}"/>
            </v-flex>
            <v-flex xs12 md6 class="pl-md-1 mt-5 mt-md-0">
                <b>Passo 4 - Atributos:</b>
                <CatAttrList title="ATRIBUTOS"  @onEditar="showAttr=true">
                    <div class="pa-1" style="overflow-y:scroll;height:150px">
                        <ul>
                            <li v-for="(item,i) in (!!business.atributos?business.atributos:[])" :key="i">{{getAttrDescricao(item)}}</li>
                        </ul>
                    </div>
                </CatAttrList>
                <Atributos :visible="showAttr" @onClose="showAttr=false" :atributosList="atributoList" 
                :atributosInit="!!business.atributos?business.atributos:[]" @onSalvar="(e)=>{business.atributos=e;$emit('onSalvarB')}"/>
            </v-flex>
        </v-layout>
        <v-flex xs12 style="margin-top:20px">
            <b>Passo 5 - Menu:</b>
            <Menu :business="business" @changeBusinessList="$emit('changeBusinessList')"/>
        </v-flex>
        <v-flex xs12 style="margin-top:20px">
            <b>Passo 6 - Fotos do Cardápio:</b>
            <Fotos :business="business" @onSalvarB="$emit('onSalvarB')" @changeBusinessList="$emit('changeBusinessList')"/>
        </v-flex>
    </v-card>
</template>

<script>
import apiG from "@/api/apiGoogle";
import Categorias from "@/components/Business/CatAttr/Categorias.vue";
import Atributos from "@/components/Business/CatAttr/Atributos.vue";
import Menu from "@/components/Business/Menu.vue";
import Fotos from "@/components/Business/Fotos.vue";
import CatAttrList from "@/components/Business/CatAttrList.vue";
export default {
    props:{
        disabled:{
            type: Boolean,
            default: false,
        },
        business:{
            type: Object,
            default: () => {}
        }
    },
    components:{
        Categorias,
        Atributos,
        CatAttrList,
        Menu,
        Fotos
    },
    data(){
        return{
            showAttr: false,
            showCat: false,
            categoriaList: [],
            atributoList: []
        }
    },
    computed:{
        linkVerify(){
            let businessID=0;
            if(!!this.business){
                businessID = this.business.id.split("locations/");
                businessID = businessID[1];
            }
            return `https://business.google.com/locations`;
        }
    },
    methods:{
        getAttrDescricao(item){
            let data = this.atributoList.filter(item2=>{
                return item2.opc.filter(item3=>{
                    // console.log(item3.atributoID==item.atributoID);
                    return item3.atributoID == item.atributoID
                }).length>0;
            });
            // console.log(data);
            if(data.length>0 && data[0].opc.length>0){
                return data[0].opc.filter(item2=>{return item2.atributoID == item.atributoID})[0].descricao;
            }else{
                return 'ATRIBUTO NÃO ENCONTRADO';
            }
        },
        async carregaAtributos(){
            let url = `trestaurante/atributos/${this.$route.params.empresaID}/${this.$route.params.filialID}`;
            let responseAttr = await apiG.get(url);
            console.log('attr',responseAttr);
            if(responseAttr.status == 200 && responseAttr.data.resultado != false){
                this.atributoList = responseAttr.data;
            }
        },
        async carregaCategorias(){
            let url = `trestaurante/categorias/${this.$route.params.empresaID}/${this.$route.params.filialID}`;
            let responseCat = await apiG.get(url);
            console.log('cat', responseCat);
            if(responseCat.status == 200 && responseCat.data.resultado != false){
                this.categoriaList = responseCat.data;
            }
        }
    },
    created(){
       
    }
}
</script>
<template>
    <v-card style="border:1px solid #2196F3" height="150" color="#f1f1f1">
        <v-layout style="height:100%">
            <v-flex xs5 sm4 style="border-right:1px solid #2196F3" class="text-center pa-3">
                <b>{{title}}</b>
                <v-btn height="40" color="blue" block class="white--text" style="margin-top:60px"
                @click="$emit('onEditar')">
                    EDITAR
                </v-btn>
            </v-flex>
            <v-flex xs7 sm8>
                <slot/>
            </v-flex>
        </v-layout>
    </v-card>
</template>

<script>
export default {
    props:{
        title:{
            type: String,
            default:""
        }
    }
}
</script>
<template>
    <v-container style="max-width:960px">
        <v-btn color="blue" class="white--text" href="https://ajuda.food.beetech.com.br/baseconhecimento/google-food-menu-manual-de-configuracao/"
        target="_blank" fab small style="position:absolute;top:10px;right:10px">
            <v-icon size="20">mdi-help</v-icon>
        </v-btn>
        <v-layout align-center style="padding:10px;margin-bottom:10px;border-bottom:1px solid rgba(0,0,0,0.3);">
            <div>
                <v-img :src="dadosEmpresa.s3Link" style="width:140px;height:140px" contain/>
            </div>
            <v-flex class="pl-5">
                <h3>{{dadosEmpresa.nomeFantasia}}</h3>
                <div>{{dadosEmpresa.enderecoCompleto}}</div>
            </v-flex>
        </v-layout>
        <GoogleAccess @onAccount="account=$event" :dadosEmpresa="dadosEmpresa" :isLogged="!!account.accountID?true:false" :account="account" ref="refBusiness"/>
    </v-container>
</template>
<script>
import GoogleAccess from "@/components/Business/GoogleAccess.vue";
import EtapaIcon from "@/components/UI/EtapaIcon.vue";
import apiN from "@/api/apiNode.js";
export default {
    components:{
        EtapaIcon,
        GoogleAccess
    },
    data(){
        return{
            dadosEmpresa:{},
            account:{}
        }
    },
    methods:{
        organizaHorarios2(dados) {
            console.log("horario", dados);
            let horarios = [];
            dados = dados.sort((a, b) => {
                return a.diaSemana - b.diaSemana;
            });
            dados.forEach((item) => {
                horarios.push({
                diaSemanaExtenso: item.diaSemanaExtenso,
                horas: (`${!!item.horaInicio ? item.horaInicio.substring(0, 5) : "00:00"} 
                    às ${!!item.horaFim ? item.horaFim.substring(0, 5) : "00:00"}`),
                horas2: (!!item.horaInicio2 || !!item.horaFim2?`${!!item.horaInicio2 ? item.horaInicio2.substring(0, 5) : "00:00"} 
                    às ${!!item.horaFim2 ? item.horaFim2.substring(0, 5) : "00:00"}`:null),
                horaInicio: !!item.horaInicio ? item.horaInicio.substring(0, 5) : "00:00",
                horaFim:  !!item.horaFim ? item.horaFim.substring(0, 5) : "23:59",
                horaInicio2: !!item.horaInicio2 ? item.horaInicio2.substring(0, 5) : null,
                horaFim2:  !!item.horaFim2 ? item.horaFim2.substring(0, 5) : null
                });
            });
            // console.log("resultadoHorario", horarios);
            return horarios;
        },
        async carregaEmpresa(){
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.$store.commit('showLoad', true);
            let dadosEmpresa = {};
            const url = `tempresa/validaDelivery`;
            let responseValida = await apiN.post(url, { 
                linkAcesso: this.$route.params.linkAcesso.toLowerCase() 
            });
            if(responseValida.status == 200){
                if(responseValida.data!=null && responseValida.data.empresaID == this.$route.params.empresaID && responseValida.data.filialID == this.$route.params.filialID){
                    dadosEmpresa = responseValida.data;
                    const urlDet = `tempresa/listaBeeshopDetalhe/${this.$route.params.empresaID}/${this.$route.params.filialID}/empresa`;
                    let responseHorario = await apiN.get(urlDet);
                    if(responseValida.status == 200){
                        responseHorario.data.forEach(item=>{
                            if (item.consulta == "horaAtendimento") {
                                if (JSON.parse(item.json).length > 0) {
                                    let horaAtendimento = JSON.parse(item.json).filter(item=>{
                                        return item.delivery==true;
                                    });
                                    dadosEmpresa.horarios = this.organizaHorarios2(horaAtendimento);
                                } else {
                                    dadosEmpresa.horarios = [];
                                }
                            }
                        });
                    }else{
                        dadosEmpresa.horarios = [];
                    }
                    console.log(dadosEmpresa);
                    this.$store.dispatch("setDadosEmpresa",dadosEmpresa);
                    this.dadosEmpresa = dadosEmpresa;
                    await this.$refs.refBusiness.validaEmpresa();
                }else{
                    this.$router.push(`/error?type=405&origin=${location.href}`);
                }
            }else{
                this.$router.push(`/error?type=406&origin=${location.href}`);
            }
            this.$store.commit('showLoad', false);
        }
    },
    created(){
        console.log(this.$route);
        // console.log(this.$store)
        if(!!this.$route.params && this.$route.params.empresaID && this.$route.params.filialID && this.$route.params.linkAcesso){
            this.carregaEmpresa();
        }else{
            this.$router.push(`/error?type=404&origin=${location.href}`);
        }
    },
    mounted(){
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}
</script>
<template>
    <v-card style="border:1px solid #2196F3" min-height="150" color="#f1f1f1" >
        <v-layout align-center class="pa-3 pb-0" :wrap="$vuetify.breakpoint.smAndDown">
            <b class="title">Menu</b>
            <v-spacer/>
            <span :class="$vuetify.breakpoint.smAndDown?'body-2':''">
                Última atualização: {{!!business.ultimaAttMenu?formataData(business.ultimaAttMenu):'--/--/---- --:--'}}
            </span>
            <v-btn class="ml-md-2 mt-2 mt-md-0 white--text" color="blue" 
            @click="onRefresh" :block="$vuetify.breakpoint.smAndDown">
                Atualizar<v-icon class="ml-2" color="white">mdi-refresh</v-icon>
            </v-btn>
        </v-layout>
        <v-layout style="overflow-x:auto;padding-left:45px !important;min-height:100px" class="pa-2" align-center ref="scrollProd" v-if="!!business.menus&&!!business.menus.produtos">
            <v-btn fab x-small color="blue" style="position:absolute;left:10px;z-index:2" @click="onArrow('left')">
                <v-icon color="white">mdi-chevron-left</v-icon>
            </v-btn>
            <div v-for="(setor,i) in business.menus.produtos" :key="i">
             
                <v-layout class="mt-2" align-center :style="i+1==business.menus.produtos.length?'':`border-right:1px dashed rgba(0,0,0,0.2);padding-right:5px;margin-right:10px`">
                    <b class="title text-uppercase mr-3" >{{setor.setor}}:</b>
                    <v-card class="pa-2 mr-2" v-for="(prod,i2) in setor.produtos" :key="i2" style="min-width:110px;width:max-content">
                        <div style="text-align:start;"><b>{{prod.descricao}}</b></div>
                        <div style="text-align:end;" class="body-2 ml-2 mt-2 green--text">R$ {{formataDinheiro(prod.venda)}}</div>
                    </v-card>
                </v-layout>
            </div>
             <v-btn fab x-small color="blue" style="position:absolute;right:10px;z-index:2" @click="onArrow('right')">
                <v-icon color="white">mdi-chevron-right</v-icon>
            </v-btn>
        </v-layout>
    </v-card>
</template>

<script>
import moment from "moment";
import apiG from "@/api/apiGoogle.js";
export default {
    props:{
		business:{
			type: Object,
			default: () => {}
		}
	},
    methods:{
        async onRefresh(){
            this.$store.commit('showLoad', true);
            let url = `trestaurante/atualizarCardapio/${this.dadosEmpresa.empresaID}/${this.dadosEmpresa.filialID}/${this.dadosEmpresa.linkAcesso}`;
            let responseAtt = await apiG.get(url);
            console.log("new", responseAtt);
            if(responseAtt.status == 200 && responseAtt.data.resultado!=false){
                // this.business = responseAtt.data[0];
                // this.$emit('changeBusinessList');

                this.$store.dispatch("setSnackbar",{
                    visible:true,
                    color:"teal",
                    center: true,
                    text:"Foi criado uma solicitação para atualizar o cardápio."
                });
            }else{
                this.$store.dispatch("setSnackbar",{
                    visible:true,
                    color:"info",
                    center: true,
                    text:"Falha ao atualizar cardápio."
                });
            }
            this.$store.commit('showLoad', false);
        },
        formataData(data){
            if(!!data){
                return `${data.substring(0,10).split("-").reverse().join("/")} ${data.substring(11,16)}`;
            }else{
                return data;
            }
        },
        onArrow(direction){
            let left = this.$refs.scrollProd.scrollLeft;
            if(direction == 'left'){
                this.$refs.scrollProd.scrollTo(left-100,0);
            }else{
                this.$refs.scrollProd.scrollTo(left+100,0);
            }
        },
        formataDinheiro(valor, decimal=2){
            let venda = valor;
            if(valor==null){
                venda = 0;
            }
            if (!!venda && venda.toString().includes(",")) {
            venda = venda.toString().replace(",", ".");
            }
            return parseFloat(venda)
            .toFixed(decimal)
            .replace(".", ",")
            .replace(/(\d)(?=(\d{3})+\,)/g, "$1.");
        }
    },
    computed:{
        dadosEmpresa() {
            return this.$store.getters["dadosEmpresa"];
        },
    }
}
</script>
<template>
    <v-layout align-center>
        <v-card :class="$vuetify.breakpoint.smAndDown?'avatarMobile':'avatar'" :style="{borderColor:type=='wait'?'#2196F3':(type=='ready'?'#4CAF50':'#FFB545')}">
            <v-img :src="require(`@/assets/${type=='wait'?'refresh':(type=='ready'?'check':'ampulheta')}.png`)" 
            class="img"  contain/>
        </v-card>
        <v-card width="100%" min-height="150"  :color="disabled?'grey':color" :disabled="disabled" class="ml-8 ml-md-0 ">
            <slot style="height:100%"/>
        </v-card>
    </v-layout>
</template>

<script>
export default {
    props:{
        type:{
            type: String,
            default: 'wait'
        },
        color:{
            type: String,
            default: '#f1f1f1'
        },
        disabled:{
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped>
    .avatar{
        width: 100px;
        height: 100px;
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 2;
        border: 10px solid;
        margin-left: -50px;
    }
    .avatarMobile{
        width: 70px;
        height: 70px;
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 2;
        border: 10px solid;
        margin-left: -4px;
    }
    .img{
        width: 40px;
        height: 40px;
    }
</style>
<template>
    <v-dialog v-model="visible" persistent max-width="500" :overlay-opacity="0.8">
        <v-card>
            <v-btn color="red" fab style="position:absolute;top:5px;left:5px;z-index:2" @click="$emit('onClose')">
                <v-icon color="white">mdi-close</v-icon>
            </v-btn>
            <v-img :src="image" contain />
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props:{
        image:{
            type: String,
            required:true,
        },
        visible:{
            type: Boolean,
            default: false
        }
    }
}
</script>

<style>

</style>
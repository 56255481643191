<template>
    <div/>
</template>

<script>
import axios from "axios";
import apiM from "@/api/apiMaps.js";
import Compressor from 'compressorjs';
export default {
    methods:{
        async onUpload(files,objTemp){
            const vm = this;
            let resultado = false;
            console.log(files);
            if (files[0] !== undefined) {
                this.$store.commit('showLoad', true);
                console.log(files[0].size, files[0].size/1000);
                const result = await new Promise((resolve, reject) => {
                    new Compressor(files[0], {
                    quality: (files[0].size/1000) < 512?0.95:0.05,
                    success: resolve,
                    error: reject,
                    });
                });
                console.log(result);
                if(!!result.size){
                    if((result.size/1000) < 12){
                         vm.$store.dispatch("setSnackbar",{
                            visible:true,
                            color:"error",
                            text:"A imagem deve possuir no mínimo 12KB."
                        });
                        this.$store.commit('showLoad', false);
                        return resultado;
                    }
                    if((result.size/1000) < 512){
                        let frResult = await this.readFile(result);
                        let api = `tutils/uploadS3`;
                        let obj = {
                        base64: frResult,
                        extensao: files[0].type,
                        bucketName: objTemp.bucketName,
                        bucketFolder: objTemp.bucketFolder,
                        };
                        console.log(obj);
                        try{
                            await apiM.post(api, obj).then((response) => {
                                console.log(response.data);
                                if (response.data != null && response.data.resultado != false) {
                                    resultado = response.data;
                                }
                                this.$store.commit('showLoad', false);
                            });
                        }catch(err){
                            vm.$store.dispatch("setSnackbar",{
                                visible:true,
                                color:"error",
                                text:"Falha ao enviar imagem."
                            });
                            this.$store.commit('showLoad', false);
                        }
                    }else{
                        this.$store.dispatch("setSnackbar",{
                            visible:true,
                            color:"error",
                            text:"A imagem deve ser no máximo de 500KB."
                        });
                        this.$store.commit('showLoad', false);
                    }
                }else{
                    this.$store.dispatch("setSnackbar",{
                        visible:true,
                        color:"error",
                        text:"Falha ao enviar imagem."
                    });
                    this.$store.commit('showLoad', false);
                }
            }else{
                this.$store.dispatch("setSnackbar",{
                    visible:true,
                    color:"error",
                    text:"Falha ao enviar imagem."
                });
                this.$store.commit('showLoad', false);
            }
            return resultado;
        },
        readFile(file){
            return new Promise((resolve, reject) => {
                const fr = new FileReader();
                fr.readAsDataURL(file);
                fr.onload = () => {
                    resolve(fr.result )
                };
                fr.onerror = reject;
            });
        }
    },
   
}
</script>

<style>

</style>
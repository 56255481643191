<template>
    <v-dialog max-width="350" v-model="visible" persistent>
        <v-card >
            <div class="body-1 pa-4">
                <span v-html="message"/>
            </div>
            <v-layout :style="`border-top:1px solid #2196F3`">
                <v-flex xs6>
                    <v-btn block color="food" height="50" text @click="$emit('onNo')">
                        {{lblNo}}
                    </v-btn>
                </v-flex>
                <v-flex xs6 :style="`border-left:1px solid #2196F3`">
                    <v-btn block color="food" height="50" text @click="$emit('onYes')">
                        {{lblYes}}
                    </v-btn>
                </v-flex>
            </v-layout>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props:{
        visible:{
            type: Boolean,
            default:false,
        },
        message:{
            type:String,
            required:true,
        },
        lblNo:{
            type: String,
            default:"Cancelar"
        },
        lblYes:{
            type: String,
            default:"Confirmar"
        }
    },
    computed:{
        
    }
}
</script>

<style>

</style>
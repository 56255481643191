import axios from "axios";

// Pode ser algum servidor executando localmente: 
// const url = "http://app.beetechapi.be/";
// const url = "http://localhost:3802/";
const url = process.env.NODE_ENV === 'production' ? 'https://app.beetechapi.be/' : 'http://localhost:3802/';
const apiN = axios.create({
  baseURL: `${url}datasnap/rest/`,
  headers:{
    'Cache-Control':'no-cache'
  },
  auth: {
    username: 'beetech',
    password: '1q2w3e4r'
  }
});


export default apiN;
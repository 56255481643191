<template>
   <v-dialog overlay-opacity="0.6" v-model="visible" persistent max-width="600" :fullscreen="$vuetify.breakpoint.smAndDown" eager>
        <v-card :style="`border-left:3px solid #2196F3`" :tile="$vuetify.breakpoint.smAndDown">
            <div  class="pa-4">
                <h3>Editar Imagem</h3>
                <v-flex xs12 class="my-5"/>
                <UploadS3 ref="refUpload"/>
                <div v-if="showLoad && this.imgSrc==''">
                    <v-skeleton-loader type="image" height="200" style="border-radius:0px" />
                    <v-skeleton-loader type="image" height="200" style="border-radius:0px" />
                </div>
                <vue-cropper v-if="imgSrc!=''" ref="cropper" :src="imgSrc"  :aspectRatio="4/3" :viewMode="1"
                alt="Imagem" :containerStyle="{maxHeight:'400px',backgroundColor:'white'}"/>
                <v-layout class="mt-5" wrap>
                    <v-flex xs12 md4 />
                    <v-flex xs6 md4 class="pr-1">
                        <v-btn height="40" outlined block @click="$emit('onClose')" >
                            Cancelar
                        </v-btn>
                    </v-flex>
                    <v-flex xs6 md4 class="pl-1">
                        <v-btn color="green" block class="white--text" height="40" @click="onSalvar">
                            SALVAR
                        </v-btn>
                    </v-flex>
                </v-layout>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import UploadS3 from "@/components/Business/Fotos/UploadS3.vue";
import Compressor from 'compressorjs';

export default {
    props:{
        visible:{
            type:Boolean,
            default:false,
        },
        imgTemp:{
            type: [Object,File],
            required:true
        },
        empresaID:{
            type:Number,
            default: 0
        }
    },
    components:{
        VueCropper,
        UploadS3
    },
    data(){
        return{
            imgSrc:""
        }
    },
    methods:{
        async onSalvar(){
            this.$store.dispatch("setShowLoad",true);
            // console.log(this.$refs.cropper);
            let img = this.$refs.cropper.getCroppedCanvas().toDataURL(this.imgTemp.type,0.95);
            img = img.split("base64,");
            console.log(this.imgTemp.type, this.imgTemp.size);
            let blob = this.b64toBlob(img[1],this.imgTemp.type, this.imgTemp.size);
            let obj = {
              base64: '',
              extensao: '',
              bucketName: "beetech-imagens-app",
              bucketFolder:`beefood/${this.dadosEmpresa.empresaID}/fotosGoogle`,
            };

            let resultado = await this.$refs.refUpload.onUpload([blob],obj);
            console.log(resultado);
            this.$emit("onSave",resultado);
        },
        b64toBlob(b64Data, contentType='', sliceSize=512){
            const byteCharacters = atob(b64Data);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                const slice = byteCharacters.slice(offset, offset + sliceSize);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            const blob = new Blob(byteArrays, {type: contentType});
            return blob;
        }
    },
    computed:{
        showLoad() {
            return this.$store.getters["showLoad"];
        },
        dadosEmpresa() {
            return this.$store.getters["dadosEmpresa"];
        },
    },
    watch:{
        async visible(){
            this.imgSrc = "";
            if(this.visible==true){
                this.$store.dispatch("setShowLoad",true);
                setTimeout( async () => {
                    // console.log(this.imgTemp);
                    let data = await this.$refs.refUpload.readFile(this.imgTemp);
                    this.imgSrc = data;
                    this.$store.dispatch("setShowLoad",false);
                }, 1);
                
            }
        }
    }
}
</script>

<style>

</style>
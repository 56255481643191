<template>
    <div>
        <div class="mt-4 mb-2">
            <b>Passo 1 - Acessar Conta:</b>
        </div>
        <EtapaIcon :type="isLogged?'ready':'do'" color="blue">
            <v-card color="transparent" style="min-height:150px" class="elevation-0 pa-4 pa-md-0" @click="onLogin" v-if="isLogged==false">
                <v-layout justify-center align-center style="min-height:150px" :wrap="$vuetify.breakpoint.smAndDown">
                    <div style="width:50px;height:50px;margin-right:20px"  class="mb-4 mb-md-0">
                        <v-img :src="require('@/assets/google.png')" style="width:50px;height:50px" />
                    </div>
                    <span style="font-size:2.4rem" class="font-weight-bold white--text" :class="$vuetify.breakpoint.smAndDown?'text-center':''">ACESSAR CONTA GOOGLE</span>
                </v-layout>
            </v-card> 
             <v-layout align-center style="min-height:150px;" :style="$vuetify.breakpoint.smAndDown?'':'padding-left:5rem !important'" 
             v-else :wrap="$vuetify.breakpoint.smAndDown" class="pa-4 pa-md-0"> 
                <div class="avatar mx-auto ml-md-0 mr-md-5 mt-7 mt-md-0 mb-3 mb-md-0" >
                    <v-img :src="account.foto" style="width:100%;height:100%;border-radius:100px"/>
                </div>
                <span style="font-size:2.4rem" class="font-weight-bold white--text mx-auto ml-md-0">
                    {{account.nome}}
                </span>
                <v-img :src="require('@/assets/google.png')" style="width:30px;height:30px;position:absolute;" 
                :style="$vuetify.breakpoint.smAndDown?'top:5px;left:5px':'bottom:5px;right:5px'" />
                <v-btn text class="pr-0" color="white" style="position:absolute;top:5px;right:10px" @click="onSairConta">
                    <v-icon class="mr-2">mdi-exit-to-app</v-icon>SAIR DA CONTA
                </v-btn>
            </v-layout>
        </EtapaIcon>
        <div class="mt-4 mb-2">
            <b>Passo 2 - Conta Business:</b>
        </div>
        <EtapaIcon :type="isLogged?!!business.id && business.id==businessTemp.id?'ready':'do':'wait'" color="blue" :disabled="isLogged == false" :style="isLogged?'':'opacity:0.5'">
            <v-layout style="min-height:150px" :wrap="$vuetify.breakpoint.smAndDown">
                <v-col v-if="businessList.length>0" cols="12" :md="!!business.id?12:6" class="pa-0">
                    <v-layout align-center justify-center wrap style="height:150px" class="white--text">
                        <v-flex xs10 md8>
                            <h2>SELECIONAR CONTA</h2>
                            <v-select :items="businessList" dense :disabled="!!business.id || businessList.length==0?true:false" 
                            outlined background-color="white" class="mt-2"   hide-details
                            item-text="titulo" item-value="id" return-object v-model="businessTemp" no-data-text="SEM RESULTADOS"/>
                            <div>
                                <v-btn v-if="!!business.id==false" :disabled="!!businessTemp.id==false" 
                                block color="green" height="40" 
                                class="white--text mt-3" @click="onConfirmarBuss"> 
                                    CONFIRMAR 
                                </v-btn>
                                <v-btn v-else block color="red" height="40" 
                                class="white--text mt-3" @click="onRemoverConta">
                                    REMOVER CONTA
                                </v-btn>
                            </div>
                        </v-flex>
                    </v-layout>
                </v-col>
                <v-col v-if="!!business.id==false" cols="12" :md="businessList.length==0?12:6" class="pa-0">
                    <v-card color="green" style="width:100%;height:100%;border-radius:0px 4px 4px 0px"
                    class="elevation-0 pa-3 pa-md-0" @click="newAccount">
                        <v-layout justify-center align-center style="height:100%">
                            <div class="text-center white--text" >
                                <h2>ADICIONAR NOVA CONTA</h2>
                                <v-icon size="75" color="white">mdi-plus</v-icon>
                            </div>
                        </v-layout>
                    </v-card>
                </v-col>
            </v-layout>
        </EtapaIcon>
        <Business ref="refBusiness" :business="business" :disabled="!!business.id?false:true"  :style="`opacity:${!!business.id?1:0.3}`"
        @onSalvarB="onSalvarB" @changeBusinessList="changeBusinessList"/>
        <MessageConfirm :visible="showMessage" :message="message" 
        @onYes="messageAct" @onNo="showMessage=false"/>
    </div>
</template>

<script>
import Business from "@/components/Business/Business";
import axios from "axios";
import apiG from "@/api/apiGoogle.js";
import EtapaIcon from "@/components/UI/EtapaIcon";
import MessageConfirm from "@/components/UI/MessageConfirm.vue";

export default {
    props:{
        isLogged:{
            type: Boolean,
            default: false
        },
        account:{
            type: Object,
            default: () => {}
        },
        dadosEmpresa:{
            type: Object,
            default: () => {}
        }
    },
    components:{
        EtapaIcon,
        Business,
        MessageConfirm
    },
    data(){
        return{
            message:"",
            messageAct: () => {},
            showMessage:false,
            businessList:[],
            business:{},
            businessTemp:{}
        }
    },
    methods:{
        onSairConta(){
            if(this.showMessage == false){
                this.message = "Tem certeza que deseja sair da conta?";
                this.messageAct = () => this.onSairConta();
                this.showMessage = true;
            }else{
                this.business={};
                this.$emit('onAccount', {});
                setTimeout(() => { 
                    this.onRemoverConta();
                }, 1);
            }
        },
        async onRemoverConta(){
            if(this.showMessage == false){
                this.message = "Tem certeza que deseja remover a conta selecionada?";
                this.messageAct = () => this.onRemoverConta();
                this.showMessage = true;
            }else{
                this.showMessage = false;
                let obj = {
                    "empresaID": this.dadosEmpresa.empresaID,
                    "filialID": this.dadosEmpresa.filialID,
                    "businessID": null,
                    "accountID": !!this.account.accountID?this.account.accountID:null
                }
                console.log(obj);
                let url = `trestaurante/sincBusiness`;
                let responseSinc = await apiG.post(url,obj);
                console.log("responseSic", responseSinc);
                if(responseSinc.status == 200 && responseSinc.data.resultado==true){
                    this.business = {};
                    this.businessTemp = {};
                }
            }
          
        },
        async onConfirmarBuss(){
            let businessID = this.businessTemp.id.split("locations/");
            businessID = businessID[1];
            let obj = {
                "empresaID": this.dadosEmpresa.empresaID,
                "filialID": this.dadosEmpresa.filialID,
                "businessID": businessID,
                "accountID": this.account.accountID
            }
            console.log(obj);
            let url = `trestaurante/sincBusiness`;
            let responseSinc = await apiG.post(url,obj);
            console.log("responseSic", responseSinc);
            if(responseSinc.status == 200 && responseSinc.data.resultado==true){
                this.business = Object.assign({},this.businessTemp);
            }
            setTimeout(() => {
                this.$store.commit('showLoad', false);
            }, 1000);
        },
        changeBusinessList(){
            let indexBusiness = this.businessList.findIndex(i => i.id === this.business.id);
            console.log(indexBusiness);
            this.businessList[indexBusiness] = this.business;
            this.businessTemp = this.business;
        },
        gerarObj(){
            // console.log(this.account);
            let obj = {
                "empresaID": this.dadosEmpresa.empresaID, 
                "filialID": this.dadosEmpresa.filialID,
                "accountID": this.account.id.replace("accounts/",""),
                "businessID": null,
                "nomeFantasia": this.dadosEmpresa.nomeFantasia,
                "descritivo": this.dadosEmpresa.descritivo,
                "telefone": this.dadosEmpresa.telefoneWhatsApp,
                "latitude": this.dadosEmpresa.latitude,
                "longitude": this.dadosEmpresa.longitude,
                "linkAcesso": `https://shop.beetech.com.br/${this.dadosEmpresa.linkAcesso}`,
                "aberto": true,
                "endereco":{
                    "cep": this.dadosEmpresa.cep,
                    "estado": this.dadosEmpresa.estado,
                    "endereco": this.dadosEmpresa.endereco,
                    "numero": this.dadosEmpresa.numero,
                    "cidade": this.dadosEmpresa.cidade
                },
                "categorias": [],
                "atributos": [],
                "fotos": [],
                "horarios": this.dadosEmpresa.horarios
            };
            return obj;
        },
        async onSalvarB(){
            console.log(this.business);
            this.$store.commit('showLoad', true);
            let url = `trestaurante/salvarBusiness`;
            let obj = this.gerarObj();
            obj.categorias = this.business.categorias;
            obj.atributos = this.business.atributos;
            obj.businessID = this.business.id.split("locations/");
            obj.businessID = obj.businessID[1];
            obj.fotos = this.business.fotos;
            console.log(obj);
            let responseBusiness = await apiG.post(url,obj);
            console.log("edit", responseBusiness);
            if(responseBusiness.status == 200 && responseBusiness.data.resultado!=false){
                let indexBusiness = this.businessList.findIndex(i => i.id === this.business.id);
                console.log(indexBusiness);
                this.businessList[indexBusiness] = responseBusiness.data;
                console.log(this.businessList[indexBusiness]);
                this.business = responseBusiness.data;
                this.businessTemp = responseBusiness.data;
            }else{
                alert("Erro ao salvar.");
            }
            this.$store.commit('showLoad', false);
        },
        async newAccount(){
            this.$store.commit('showLoad', true);
            let url = `trestaurante/salvarBusiness`;
            let obj = this.gerarObj();
            let responseBusiness = await apiG.post(url,obj);
            console.log("new", responseBusiness);
            if(responseBusiness.status == 200 && responseBusiness.data.resultado!=false){
                this.businessList = [responseBusiness.data];
                this.businessTemp = responseBusiness.data;
                this.onConfirmarBuss();
            }else{
                this.$store.commit('showLoad', false);
                alert("ERRO AO ADICIONAR NOVA CONTA");
            }
        },
        async carregaBusiness(){
            this.$store.commit('showLoad', true);
            let url = `trestaurante/listaBusiness/${this.$route.params.empresaID}/${this.$route.params.filialID}`;
            let responseBusiness = await apiG.get(url);
            console.log(responseBusiness);
            if(responseBusiness.status == 200 && responseBusiness.data.resultado!=false){
                this.businessList = responseBusiness.data;
                if(!!this.account.businessID){
                    let businessSelect = this.businessList.filter(item=>{
                        return item.id.includes(this.account.businessID)==true;
                    });
                    if(!!businessSelect && businessSelect.length>0){
                        this.businessTemp = businessSelect[0];
                        this.business = businessSelect[0];
                    }else{
                        this.businessTemp = this.businessList[0];
                    }
                }
            }
            this.$store.commit('showLoad', false);
        },
        onLogin(){
            auth2.grantOfflineAccess().then(async (authResult)=>{
                this.$store.commit('showLoad', true);
                let obj = { 
                    'code' : authResult['code'],
                    'client_id' : '330954761889-5unm0livavf3v4hdr5ef2iimbsop0j5u.apps.googleusercontent.com',
                    'client_secret' : "-YcI51ECcgUL2KTr7t024gI_",
                    'redirect_uri' : location.origin,
                    'grant_type' : 'authorization_code'
                };
                let responseCode = await axios.post('https://www.googleapis.com/oauth2/v4/token',obj);
                console.log(responseCode);
                if(responseCode.status == 200){
                    let url = "tauth/validaGoogle";
                    obj = {
                        "empresaID": this.$route.params.empresaID,
                        "filialID": this.$route.params.filialID,
                        "refresh_token": responseCode.data.refresh_token
                    };
                    let responseGoogle = await apiG.post(url,obj);
                    console.log(responseGoogle);
                    if(responseGoogle.status == 200){
                        await this.initBusiness(responseGoogle.data);
                    }
                }
                this.$store.commit('showLoad', false);

            }).catch(err=>{
                console.log('err',err);
            });
        },
        async initBusiness(data){
            this.$emit("onAccount", data);
            console.log(this.$refs.refBusiness);
            await this.carregaBusiness();
            await this.$refs.refBusiness.carregaAtributos();
            await this.$refs.refBusiness.carregaCategorias();
        },
        async validaEmpresa(){
            this.$store.commit('showLoad', true);
            let url = "tauth/validaEmpresa";
            let obj = {
                "empresaID": this.$route.params.empresaID,
                "filialID": this.$route.params.filialID
            };
            let responseGoogle = await apiG.post(url,obj);
            // console.log(responseGoogle);
            if(responseGoogle.status == 200 && responseGoogle.data.resultado!=false){
                await this.initBusiness(responseGoogle.data);
            }
            this.$store.commit('showLoad', false);
        }
    },
    watch:{
        business(){
            console.log(this.business);
        }
    },
    created(){
    }
}
</script>

<style scoped>
.avatar{
    width: 75px;
    height: 75px;
    border-radius: 100px;
    border: 5px solid white;
}
</style>
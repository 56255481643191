<template>
    <v-dialog v-model="visible" :fullscreen="$vuetify.breakpoint.smAndDown" persistent max-width="500" scrollable>
        <v-card :tile="$vuetify.breakpoint.smAndDown" style="max-height:700px">
            <v-card-title>
                <h2>Categoria</h2>
                <p class="body-1 mt-3">Ajude os clientes a encontrar sua empresa por setor.</p>
            </v-card-title>
            <v-card-text>
                <v-flex xs12>
                    <b>Categoria principal</b>
                    <v-autocomplete :items="categoriasUsed(categoriaPrincipal)" v-model="categoriaPrincipal" item-text="categoria" return-object item-value="categoriaID" 
                    no-data-text="Sem Resultados" no-results-text="Sem Resultados"/>
                </v-flex>
                <v-flex xs12>
                    <b>Categorias adicionais</b>
                    <div v-for="(item,i) in categoriasAdicionais" :key="i">
                        <v-autocomplete :items="categoriasUsed(item)" v-model="categoriasAdicionais[i]"  item-text="categoria" item-value="categoriaID"
                        return-object :append-outer-icon="'mdi-close'" @click:append-outer="()=>{categoriasAdicionais.splice(i,1);categoriasAdicionais.length<=1?categoriasAdicionais.push({}):''}"
                        no-data-text="Sem Resultados" no-results-text="Sem Resultados"/>
                    </div>
                    <v-btn text color="blue" @click="categoriasAdicionais.push({})">Adicionar outra categoria</v-btn>
                </v-flex>
            </v-card-text>
            <v-card-actions>
                <v-layout  wrap>
                    <v-flex xs12 md6/>
                    <v-flex xs6 md3 class="pr-1">
                        <v-btn text color="#424242"  @click="$emit('onClose')">
                            CANCELAR
                        </v-btn>
                    </v-flex>
                    <v-flex xs6 md3 class="pl-1">
                        <v-btn text color="blue" @click="onSalvar">
                            APLICAR
                        </v-btn>
                    </v-flex>
                    <v-flex xs12>
                        <v-divider class="my-2"/>
                        <p class="body-2">As edições serão revisadas. Pode levar até três dias para que elas fiquem visíveis na Pesquisa, no Maps e em outros Serviços do Google.</p>
                    </v-flex>
                </v-layout>
               
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props:{
        visible:{
            type: Boolean,
            default: false
        },
        categoriasInit:{
            type: Array,
            default: ()=>[]
        },
        categoriasList:{
            type: Array,
            default: ()=>[]
        }
    },
    data(){
        return{
            categoriaPrincipal:{},
            categoriasAdicionais:[]
        }
    },
    methods:{
        categoriasUsed(item){
            // console.log(item);
            return this.categoriasFilter.concat(item).filter(item=>{return item.categoriaID!=undefined});
        },
        onSalvar(){
            let categoriasFinal = this.categoriasAdicionais.filter(item=>{
                return item.categoriaID!=undefined;
            });
            categoriasFinal = [this.categoriaPrincipal].concat(categoriasFinal);
            this.$emit("onSalvar", categoriasFinal);
            this.$emit("onClose");
        }
    },
    watch:{
        visible(){
            if(this.categoriasInit.length>0){
                console.log(this.categoriasList,this.categoriasInit);
                this.categoriaPrincipal = this.categoriasInit[0];
                this.categoriasAdicionais = this.categoriasInit.filter((item,i)=>{return i>0});
                if(this.categoriasAdicionais.length == 0){
                    this.categoriasAdicionais.push({});
                }
            }
        }
    },
    computed:{
        categoriasFilter(){
            // console.log(this.categoriasList,this.categoriasInit);
            return this.categoriasList.filter(item=>{
                return this.categoriasAdicionais.concat([this.categoriaPrincipal]).filter(item2=>{
                    return item2.categoriaID==item.categoriaID;
                }).length==0;
            });
        }
    }
}
</script>
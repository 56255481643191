<template>
    <v-card style="border:1px solid #2196F3" height="150" color="#f1f1f1" class="pa-3">
        <v-btn fab color="green" style="position:absolute;top:-26px;right:15px;z-index:2" @click="$refs.inputF.click()">
            <v-icon color="white">mdi-plus</v-icon>
        </v-btn>
        <input type="file" id="file" style="display:none" accept="image/*" ref="inputF" @change="onFilePicked">
        <ModalCortaImg :visible="showCortaImg" :imgTemp="imgEdit" @onClose="showCortaImg=false" @onSave="onSaveImg" />
        <MessageConfirm :visible="showMessage" message="Confirma a exclusão da foto?" @onYes="onDeleteFoto(fotoTemp, indexTemp)" @onNo="showMessage=false"/>
        <PreviewImg :visible="showPreview" @onClose="showPreview=false" :image="!!fotoTemp.url?fotoTemp.url:''"/>
        <v-layout style="overflow-x:auto;padding-left:45px !important;min-height:124px" class="pa-2" align-center ref="scrollFoto" v-if="!!business && !!business.fotos">
            <v-btn fab x-small color="blue" style="position:absolute;left:10px;z-index:2" @click="onArrow('left')">
                <v-icon color="white">mdi-chevron-left</v-icon>
            </v-btn>
            <v-card v-for="(ft,i) in business.fotos" :key="i" class="mr-7">
                <v-btn fab x-small color="red" style="position:absolute;top:-10px;right:-10px;z-index:2" @click="onDeleteFoto(ft,i)">
                    <v-icon color="white">mdi-delete</v-icon>
                </v-btn>
                <v-btn fab x-small color="blue" style="position:absolute;top:-10px;left:-10px;z-index:2" @click="onPreview(ft,i)">
                    <v-icon color="white">mdi-image</v-icon>
                </v-btn>
                <v-img :src="ft.url"  style="height:100px;width:160px"/>
            </v-card>
             <v-btn fab x-small color="blue" style="position:absolute;right:10px;z-index:2" @click="onArrow('right')">
                <v-icon color="white">mdi-chevron-right</v-icon>
            </v-btn>
        </v-layout>
    </v-card>
</template>

<script>
import PreviewImg from "@/components/UI/PreviewImg.vue";
import ModalCortaImg from "@/components/Business/Fotos/ModalCortaImg.vue";
import apiG from "@/api/apiGoogle.js";
import MessageConfirm from "@/components/UI/MessageConfirm"
export default {
    props:{
		business:{
			type: Object,
			default: () => {}
		}
	},
    components:{
        ModalCortaImg,
        MessageConfirm,
        PreviewImg,
    },
    data(){
        return{
            imgEdit:{},
            showCortaImg:false,
            fotoTemp:{},
            indexTemp:null,
            showMessage:false,
            showPreview: false,
        }
    },
    computed:{
        dadosEmpresa() {
            return this.$store.getters["dadosEmpresa"];
        },
    },
    methods:{
        onPreview(ft){
            this.fotoTemp = ft;
            this.showPreview = true;
        },
        async onDeleteFoto(ft,i){
            if(this.showMessage==false){
                this.fotoTemp = ft;
                this.indexTemp = i;
                this.showMessage = true;
            }else{
                this.showMessage = false;
                this.$store.commit('showLoad', true);
                let url = `trestaurante/excluirFotosProdutos/${this.dadosEmpresa.empresaID}/${this.dadosEmpresa.filialID}/${ft.id}`;
                console.log(url);
                let responseFoto = await apiG.delete(url);
                if(responseFoto.status==200 && responseFoto.data.resultado!=false){
                    this.business.fotos.splice(i,1);
                    this.$emit("changeBusinessList");
                }else{
                    this.$store.dispatch("setSnackbar",{
                        visible:true,
                        color:"error",
                        text:"Falha ao excluir imagem."
                    });
                }
                this.$store.commit('showLoad', false);
            }
        },
        onSaveImg(resultado){
            if(resultado!=false && !!resultado.link){
                this.business.fotos.push({
                    id:null,
                    url: resultado.link,
                    formato: "PHOTO"
                });
                this.$emit("onSalvarB");
                this.showCortaImg = false;
            }
        },
        async onFilePicked(e) {
            // console.log(this.$refs.inputF);
            const files = e.target.files;
            if (files[0] !== undefined) {
                this.imgEdit = files[0];
                console.log(this.imgEdit);
                this.showCortaImg = true;
            }
            this.$refs.inputF.value = null;
        },
        onArrow(direction){
            let left = this.$refs.scrollFoto.scrollLeft;
            if(direction == 'left'){
                this.$refs.scrollFoto.scrollTo(left-100,0);
            }else{
                this.$refs.scrollFoto.scrollTo(left+100,0);
            }
        },
    }
}
</script>